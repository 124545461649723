import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tree from "react-d3-tree";
import { useSelector } from "react-redux";
import axios from "axios";
import userSessionHandle from "../../userSessionHandle"
import SignIn from "layouts/authentication/sign-in";
import { useNavigate } from "react-router-dom";

const Treeview = () => {
  const [treeData, settreedata] = useState([]);
  const [dataTree, setdataTree] = useState([])
  // userSessionHandle()
  const user_id = useSelector((state) => state.session.userId ? state.session.userId.value : null);
    // console.log(user_id);
  const sessionId = useSelector((state) => state.session.sessionId || null );
  const navigate = useNavigate()

  const getTreeData = async () => {
    
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/Treeview.php";
    const payload = {
      session_id: sessionId,
      parent_id: user_id,
    };

    try {
      const response = await axios({
        method: "get",
        url: apiUrl,
        params: payload,
      });
      console.log(response.data);
      settreedata(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  /// data get for tree view //
  const fetchData = async () => {
    
    console.log(user_id);
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "get_entry_list";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Ref_Refferals",
      // query: "ref_refferals_cstm.user_id_c= 1",
      
      select_fields: [
        "id",
        "refferid_c",
        "firstname_c",
        "lastname_c",
        "email_c",
        "phone_number_c",
        
      ],
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;
    

    try {
      const response = await axios.get(fullUrl);
      console.log("API Response:", response.data);
      const entryList = response.data.entry_list;

      // Extracting name_value_list from each entry in entry_list and storing them in the products array
      const products = entryList.map((entry) => entry.name_value_list);
      // const sortedData = products.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      console.log(products);
      setdataTree(products);
      // console.log(products[1].id);

      // console.log(referrals, "data");
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during get data:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  useEffect(() => {
    
    

    if (sessionId) {
      fetchData();
      
    }
    
  }, [sessionId]);



  ///end
 
  const handleNodeClick = (nodeData) => {
    
    // setSelectedUser(nodeData); 
    const data = nodeData.__rd3t.id
    console.log(data);
    
    
    



   
    
     // Set user data
    // setIsModalOpen(true);       // Open modal
  };


  useEffect(() => {
    getTreeData();
    
  }, [sessionId]);
  useEffect(() => {
    if (!sessionId) {
      navigate('/authentication/sign-in'); // Redirect to login if sessionId is null
    }
  }, [sessionId, navigate]);

  return (
    <>
      {!sessionId ? (
        <SignIn />
      ) : (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={3}>
            <MDBox py={3} className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div id="treeWrapper" style={{ width: "750%", height: "100vh" }}>
                {treeData.length > 0 ? (
                  <Tree
                    data={treeData}
                    onNodeClick={(node) => handleNodeClick(node.data)}
                     // Pass the API response directly to the Tree component
                    pathFunc="step"
                    orientation="vertical"
                    nodeSvgShape={{
                      shape: "rect",
                    }}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                  />
                ) : (
                  <p>Loading tree data...</p> // Loading message while data is being fetched
                )}
              </div>
            </MDBox>

            <MDBox></MDBox>
          </MDBox>
        </DashboardLayout>
      )}
    </>
  );
};

export default Treeview;
