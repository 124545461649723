import { Language } from "@mui/icons-material";
import axios from "axios";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SignIn from "layouts/authentication/sign-in"

import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

const addJobApplication = () => {
  
  const [isDetailview, setIsDetailView] = useState("table1");
  const handleOpenDetailView = (table) => {
    setIsDetailView((prevTable) => (prevTable === table ? null : table));
  };
  const sessionId = useSelector((state) => state.session.sessionId);
  const [formData, setFormData] = useState({
    // Form 1
    form1: {
      category: "",
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      phoneNumber: "",
      alternativePhoneNumber: "",
      gender: "",
      nationality: "",
      disability: "", // corrected typo
      street: "",
      city: "",
      country: "",
      zipCode: "",
      aadhar: "", // corrected typo
      passport: "",
      panCard: "",
      language: "", // corrected typo
    },
    // Form 2
    form2: {
      qualification: "",
      sslc: "",
      hsc: "",
      diploma: "",
      bachelorDegree: "",
      masterDegree: "",
      other: "",
      university: "", // corrected typo
      graduationYear: "",
    },
    // Form 3
    form3: {
      experience: "",
      yearsOfExperience: "",
      position: "",
      salary: "",
      country: "", // corrected typo
      workPermit: "",
      coverLetter: "",
      resume:"",
      skills: "",
    },
  });
  const formRefs = {
    form1: useRef(null),
    form2: useRef(null),
    form3: useRef(null),
  };

  const handleChangeAddJob = (formName, e) => {
    const { name, value, type, files } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [formName]: {
        ...prevState[formName],
        [name]: type === "file" ? files[0] : value, // Handle file inputs differently
      },
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "APP_Job_Application",
      name_value_list: [
        
        { name: "category_c", value: formData.form1.category },
        { name: "firstname_c", value: formData.form1.firstName },
        { name: "lastname_c", value: formData.form1.lastName },
        { name: "email_c", value: formData.form1.email },
        { name: "dob_c", value: formData.form1.dob },
        { name: "phone_number_c", value: formData.form1.phoneNumber },
        { name: "alternativenumber_c", value: formData.form1.alternativePhoneNumber },
        { name: "gender_c", value: formData.form1.gender },
        { name: "nationality_c", value: formData.form1.nationality },
        { name: "disability_c", value: formData.form1.disability },
        { name: "street_c", value: formData.form1.street },
        { name: "city_c", value: formData.form1.city },
        { name: "country_c", value: formData.form1.country },
        { name: "zipcode_c", value: formData.form1.zipCode },
        { name: "aadthar_c", value: formData.form1.aadhar },
        { name: "passport_c", value: formData.form1.passport },
        { name: "pancard_c", value: formData.form1.panCard },
        { name: "languaguagesknown_c", value: formData.form1.language },

        /// form 2
        { name: "qualification_c", value: formData.form2.qualification },        
        { name: "sslc_c", value: formData.form2.sslc },
        { name: "hsc_c", value:formData.form2.hsc },
        { name: "diploma_c", value: formData.form2.diploma },
        { name: "bachelordegree_c", value: formData.form2.bachelorDegree },
        { name: "masterdegree_c", value: formData.form2.masterDegree },
        { name: "other_c", value: formData.form2.other },
        { name: "university_c", value: formData.form2.university },
        { name: "graduationyear_c", value: formData.form2.graduationYear },

        ///form 3
        { name: "experience_c", value: formData.form3.experience },
        { name: "yearofexperience_c", value: formData.form3.yearsOfExperience },
        { name: "position_c", value: formData.form3.position },
        { name: "salary_c", value: formData.form3.salary },
        { name: "country_c", value: formData.form3.country },
        { name: "workpermit_c", value: formData.form3.workPermit },
        { name: "coverletter_c", value: formData.form3.coverLetter },
        { name: "resume_c", value: formData.form3.resume },
        { name: "skills_c", value: formData.form3.skills },
      ],
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;

    try {
      const response = await axios.post(fullUrl);
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };
  const handleSubmitAll = () => {
    Object.keys(formRefs).forEach((formName) => {
      if (formRefs[formName].current) {
        formRefs[formName].current.requestSubmit(); // Trigger form submission programmatically
      }
    });
  };

  return (
    <>
     {!sessionId ?  <SignIn /> :
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <div className="flex justify-end p-5 gap-4">
            <button
              className="cursor-pointer rounded px-3 shadow bg-blue-900 text-white"
              type="button"
              onClick={handleSubmitAll}
            >
              save
            </button>
            <button className="cursor-pointer rounded px-3 shadow bg-red-500 text-white">
              Cancel
            </button>
          </div>
          <div className="flex justify-start  w-full p-2 cursor-pointer gap-4">
            <div
              className={`cursor-pointer rounded px-3 shadow py-3 ${
                isDetailview === "table1" ? "text-blue-900 bg-white" : "bg-blue-900 text-white"
              }`}
              onClick={() => handleOpenDetailView("table1")}
            >
              Personal Details
            </div>
            <div
              className={`cursor-pointer rounded px-3 shadow py-3 ${
                isDetailview === "table2" ? "text-blue-900 bg-white" : "bg-blue-900 text-white"
              }`}
              onClick={() => handleOpenDetailView("table2")}
            >
              Education Details
            </div>
            <div
              className={`cursor-pointer rounded px-3 shadow py-3 ${
                isDetailview === "table3" ? "text-blue-900 bg-white" : "bg-blue-900 text-white"
              }`}
              onClick={() => handleOpenDetailView("table3")}
            >
              Experience Details
            </div>
          </div>

          <div className="w-full mt-4">
            {isDetailview === "table1" && (
              <div className="table w-full">
                <form className="w-full" ref={formRefs.form1} onSubmit={(e) => handleSubmit(e, "form1")}>
                  <div className="table-row-group">
                    <div className="table-row">
                      <div className="table-cell w-1/5  p-2 ">
                        <div>Category </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.category}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="category"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>First Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.firstName}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="firstName"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Last Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.lastName}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="lastName"
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell w-1/5  p-2">
                        <div>Email </div>

                        <input
                          type="email"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.email}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="email"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Date of Birth </div>

                        <input
                          type="date"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.dob}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="dob"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Phone Number </div>

                        <input
                          type="number"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.phoneNumber}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="phoneNumber"
                        />
                      </div>
                    </div>
                    <div className="table-row ">
                      <div className="table-cell w-1/5  p-2">
                        <div>Alternative Phone Number </div>

                        <input
                          type="number"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.alternativePhoneNumber}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="alternativephonenumber"
                        />
                      </div>
                      <div className="table-cell w-1/5 p-2">
                        <div>Gender</div>
                        <select
                          name="gender"
                          value={formData.form1.gender}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Nationality </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.nationality}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="nationality"
                        />
                      </div>
                    </div>
                    <div className="table-row ">
                      <div className="table-cell w-1/5 p-2">
                        <div>Disability</div>
                        <select
                          name="disability"
                          value={formData.form1.disability}
                          
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Street </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.street}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="street"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>City </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.city}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="city"
                        />
                      </div>
                    </div>
                    <div className="table-row ">
                      <div className="table-cell w-1/5  p-2">
                        <div>Country </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.country}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="country"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>ZipCode </div>

                        <input
                          type="number"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.zipCode}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="zipCode"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Aadhar Card </div>

                        <input
                          type="file"
                          // value="aadhar.pdf"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.aadhar}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="aadhar"
                        />
                      </div>
                    </div>
                    <div className="table-row ">
                      <div className="table-cell w-1/5  p-2">
                        <div>PassPort </div>

                        <input
                          type="file"
                          // value="passport.pdf"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.passport}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="passport"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Pan Card </div>

                        <input
                          type="file"
                          // value="pancard.pdf"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.panCard}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="panCard"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Language known </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form1.language}
                          onChange={(e) => handleChangeAddJob("form1", e)}
                          name="language"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isDetailview === "table2" && (
              <div className="table w-full">
                <form className="w-full" ref={formRefs.form2} onSubmit={(e) => handleSubmit(e, "form2")}>
                  <div className="table-row-group">
                    <div className="table-row">
                      <div className="table-cell w-1/5  p-2 ">
                        <div>Qualification </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.qualification}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="qualification"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>SSLC </div>
                        <input
                          type="file"
                          // value="SSLC"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.sslc}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="sslc"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>HSC </div>

                        <input
                          type="file"
                          //  value="Raj"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.hsc}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="hsc"
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell w-1/5  p-2">
                        <div>Diploma </div>

                        <input
                          type="email"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.diploma}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="diploma"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Bachelor Degree </div>

                        <input
                          type="file"
                          //  value="01/01/2024"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.bachelorDegree}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="bachelorDegree"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Master Degree </div>

                        <input
                          type="file"
                          //  value="9876543210"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.masterDegree}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="masterDegree"
                        />
                      </div>
                    </div>
                    <div className="table-row ">
                      <div className="table-cell w-1/5  p-2">
                        <div>Other </div>

                        <input
                          type="file"
                          //  value="9876543210"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.other}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="other"
                        />
                      </div>

                      <div className="table-cell w-1/5  p-2">
                        <div>University </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.university}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="university"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Graduation Year </div>

                        <input
                          type="number"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form2.graduationYear}
                          onChange={(e) => handleChangeAddJob("form2", e)}
                          name="graduationYear"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isDetailview === "table3" && (
              <div className="table w-full">
                <form className="w-full" ref={formRefs.form3} onSubmit={(e) => handleSubmit(e, "form3")}>
                  <div className="table-row-group">
                    <div className="table-row">
                      <div className="table-cell w-1/5  p-2 ">
                        <div>Do you have Experience </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.experience}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="experience"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Years of Experience </div>
                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.yearsOfExperience}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="yearsOfExperience"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Current/Past Position </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.position}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="position"
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell w-1/5  p-2">
                        <div>Current/Past Salary </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.salary}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="salary"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Country </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.country}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="country"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Work Permit file </div>

                        <input
                          type="file"
                          //  value="9876543210"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.workPermit}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="workPermit"
                        />
                      </div>
                    </div>
                      
                    </div>
                    <div className="table-row ">
                    <div className="table-cell w-1/5  p-2">
                        <div>Resume </div>

                        <input
                          type="file"
                          //  value="9876543210"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.resume}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="resume"
                        />
                      </div>
                      <div className="table-cell w-1/5  p-2">
                        <div>Cover Letter </div>

                        <textarea
                          type=""
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.coverLetter}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="coverLetter"
                        ></textarea>
                      </div>

                      <div className="table-cell w-1/5  p-2">
                        <div>Skills </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={formData.form3.skills}
                          onChange={(e) => handleChangeAddJob("form3", e)}
                          name="skills"
                        />
                      </div>
                    </div>
                  
                </form>
              </div>
            )}
          </div>
        </MDBox>
      </DashboardLayout>
}
    </>
  );
};

export default addJobApplication;
