import React, { useEffect } from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import SignIn from "layouts/authentication/sign-in"

const updateUser = () => {
    const { id } = useParams();
    // console.log("customer id",id);
    
    const [updateJob, setUpdateJob] = useState({
        role: "",
        company: "",
        location: "",
        type: "",
        category: "",
        skills: "",
        closeDate: "",
        
    })

    const navigate = useNavigate()
    const sessionId = useSelector((state) => state.session.sessionId);
    const user_id = useSelector((state) => state.session.userId ? state.session.userId.value : null);
    console.log(user_id);
    
    
    useEffect(() => {
      const fetchData = async () => {
        const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
        const method = "get_entry_list";
        const input_type = "JSON";
        const response_type = "JSON";
        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "job_JOB_",
          // query: "ref_refferals_cstm.user_id_c= 1",
          
          select_fields: [
            "id",
            "name",
            "company_name_c",
            "location_c",
            "job_type_c",
            "category_c",
            "required_skills_c",
            "closedate_c"
            
          ],
          query: id ? `job_job_.id = '${id}'` : "",
        });
        console.log(rest_data);
  
        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
          rest_data
        )}`;
  
        try {
            const response = await axios.get(fullUrl);
            console.log("API Response:", response.data);
            const entryList = response.data.entry_list;
          
            // Extracting name_value_list from each entry in entry_list and storing them in the products array
            const products = entryList.map((entry) => entry.name_value_list);
            console.log("Products:", products); // Log to inspect the structure
          
            if (products.length > 0) {
              const jobUpdateData = products[0]; // Directly get the first user data
              
              console.log("User Data:", jobUpdateData); // Inspect the structure
          
              // Set the updateUser state with the relevant data
              setUpdateJob({
                role: jobUpdateData.name.value || '',
                company: jobUpdateData.company_name_c.value || '',
                location: jobUpdateData.location_c.value || '',
                
                category: jobUpdateData.category_c.value || '',
                skills: jobUpdateData.required_skills_c.value || '',
                closeDate: jobUpdateData.closedate_c.value || '',
                
              });
            } else {
              console.warn("No entries found in entryList.");
            }
          } catch (error) {
          if (error.response) {
            console.error("An error occurred during get data:", error.response.data);
            console.error("Status code:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        }
      };
  
      if (sessionId) {
        fetchData();
      }
    }, [sessionId]);
    

    const handleUpdateChange = (e) => {
        const { name, value } = e.target;
        setUpdateJob((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const handleSubmit = async(e) =>{
        e.preventDefault();

        const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";
    
        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "job_JOB_",
          name_value_list: [
        { name: "id", value: id },
        { name: "name", value: updateJob.name },
        { name: "company_name_c", value: updateJob.company },
        { name: "location_c", value: updateJob.location },
        { name: "category_c", value: updateJob.category },
        { name: "required_skills_c", value: updateJob.skills },
        { name: "closedate_c", value: updateJob.closeDate },
        
        
          ]
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
          rest_data
        )}`;
    
        try {
          const response = await axios.put(fullUrl);
          console.log("API Response:", response.data);
          navigate("/jobListing")
        } catch  (error) {
          if (error.response) {
            console.error("An error occurred during login:", error.response.data);
            console.error("Status code:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        }
    
    }
  return (
    <>
    {!sessionId ? < SignIn /> :
    <DashboardLayout>
       <DashboardNavbar />
       <MDBox py={3}>
        <div className='flex flex-col'>
       <form className="w-full flex flex-col justify-center" onSubmit={handleSubmit} >
        <div className="flex justify-end">
       <div className="flex content-end p-5 gap-4">
            <button
              className="cursor-pointer rounded px-3 shadow bg-blue-900 text-white"
              type="submit"
              
            >
              update
            </button>
            <button className="cursor-pointer rounded px-3 shadow bg-red-500 text-white">
              Cancel
            </button>
          </div>
          </div>
          <div className="w-full mt-4">
            
              <div className="table w-full">
                
                  <div className="table-row-group">
                    <div className="flex flex-col content-center">
                     
                      <div className="table-cell p-2">
                        <div>Role </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateJob.role}
                          onChange={handleUpdateChange}
                          name="role"
                        />
                      </div>
                      <div className="table-cell p-2">
                        <div>Company Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateJob.company}
                          onChange={handleUpdateChange}
                          name="company"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Location </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateJob.location}
                          onChange={handleUpdateChange}
                          name="location"
                        />
                      </div>
                    
                      <div className="table-cell   p-2">
                        <div>Category </div>

                        <input
                          type="email"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateJob.category}
                          onChange={handleUpdateChange}
                          name="category"
                        />
                      </div>
                     
                      <div className="table-cell   p-2">
                        <div>Skills </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateJob.skills}
                          onChange={handleUpdateChange}
                          name="skills"
                          // pattern="[0-9]{10}"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Close Date </div>

                        <input
                          type="date"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateJob.closeDate}
                          onChange={handleUpdateChange}
                          name="closeDate"
                          
                        />
                      </div>
                      
                    </div>
                   
                  </div>
               
              </div>
              </div>
              </form>
           
              </div>
       
         <MDBox>
           
         </MDBox>
       </MDBox>
       
     </DashboardLayout>
}
     </>
  )
}

export default updateUser