import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { FaArrowAltCircleRight, FaSearch } from "react-icons/fa";
import { FaCodeBranch } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import button from "assets/theme/components/button";
import { Sort, Style } from "@mui/icons-material";
import { color, width } from "@mui/system";
import { GiPalmTree } from "react-icons/gi";
import Filter from "../../components/Filter/filter";
import Tree from "../../components/treeView/treeView";
import Login from "layouts/authentication/sign-in";

import axios from "axios";
import { useSelector } from "react-redux";

const jobApplication = () => {
  const [treeOpen, setTreeOpen] = useState(false);
  const [application, setApplication] = useState([]);

  const sessionId = useSelector((state) => state.session.sessionId);

  console.log("Session ID:", sessionId);

  console.log("test");

  const OpenTree = () => {
    console.log("test");
    setTreeOpen(true);
  };
  const closeTree = () => {
    setTreeOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "APP_Job_Application",
        select_fields: [
          "id",
          "category_c",
          "firstname_c",
          "lastname_c",
          "email_c",
          "dob_c",
          "phone_number_c",
          "alternativenumber_c",
          "gender_c",
          "nationality_c",
          "disability_c",
          "street_c",
          "city_c",
          "country_c",
          "zipcode_c",
          "aadthar_c",
          "passport_c",
          "pancard_c",
          "languaguagesknown_c",
          "qualification_c",
          "sslc_c",
          "hsc_c",
          "diploma_c",
          "bachelordegree_c",
          "masterdegree_c",
          "other_c",
          "university_c",
          "graduationyear_c",
          "experience_c",
          "yearofexperience_c",
          "position_c",
          "salary_c",
          "country_c",
          "workpermit_c",
          "coverletter_c",
          "coverletter_c",
          "resume_c",
          "skills_c",
        ],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
        rest_data
      )}`;

      try {
        const response = await axios.get(fullUrl);
        console.log("API Response:", response.data);
        const entryList = response.data.entry_list;

        // Extracting name_value_list from each entry in entry_list and storing them in the products array
        const products = entryList.map((entry) => entry.name_value_list);
        console.log(products);
        setApplication(products);
      } catch (error) {
        if (error.response) {
          console.error("An error occurred during get data:", error.response.data);
          console.error("Status code:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId]);

  const handleUpdate = ({
    category_c,
    firstname_c,
    lastname_c,
    email_c,
    dob_c,
    phone_number_c,
    alternativenumber_c,
    gender_c,
    nationality_c,
    disability_c,
    street_c,
    city_c,
    country_c,
    zipcode_c,
    aadthar_c,
    passport_c,
    pancard_c,
    languaguagesknown_c,
    qualification_c,
    sslc_c,
    hsc_c,
    diploma_c,
    bachelordegree_c,
    masterdegree_c,
    other_c,
    university_c,
    graduationyear_c,
    experience_c,
    yearofexperience_c,
    position_c,
    salary_c,

    workpermit_c,
    coverletter_c,
    resume_c,
    skills_c,
  }) => {};

  const deleteJobApplication = async (id) => {
    // setLoading(true);
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "APP_Job_Application",
      name_value_list: [
        {
          name: "id",
          value: id,
        },
        {
          name: "deleted",
          value: 1,
        },
      ],
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;
    try {
      const response = await axios.post(fullUrl);

      console.log("deleted", response.data);
      // setDeleted(true);
      setApplication((prevApplication) =>
        prevApplication
          .filter((jobApplication) => jobApplication.id.value !== id)
         
      );
      // setReferral((prevReferrals) => prevReferrals.filter((referral) => referral.id !== id));
    } catch {
      console.error("Error deleting product:");
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <Link to="/jobapplication/DetailView">
          <p onClick={handleUpdate(row)}>{row.firstname_c.value}</p>
        </Link>
      ),
      style: {
        color: "blue",
        textDecoration: "underline",
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_c.value,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number_c.value,
      sortable: true,
    },
    {
      name: "Job Role",
      selector: (row) => row.category_c.value,
    },
    {
      name: "Status",
      selector: (row) => (
        <div class="dropdown">
          <select name="cars" id="cars">
            <option value="inProgress">In Progress</option>
            <option value="selected">Selected</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      ),
    },

    {
      name: "Actions",
      width: "10%",
      cell: (row) => (
        <div className="py-4 flex flex-row gap-3">
          {/* <GiPalmTree className="text-green-700 text-xl cursor-pointer" onClick={OpenTree} /> */}

          <MdDelete
            className="text-red-700 text-xl cursor-pointer"
            onClick={() => deleteJobApplication(row.id.value)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {!sessionId ? (
        <Login />
      ) : (
        <div className="">
          <DashboardLayout>
            <DashboardNavbar />

            <MDBox py={3}>
              <Filter />
              <MDBox>
                <div class="static overflow-x-auto pt-2 pb-2   ">
                  <div className="flex justify-between bg-blue-300 p-4 rounded items-center ">
                    <div className="text-blue-950">Job Application</div>
                    <Link to="/jobapplication/addJobApplication">
                      <button className="bg-blue-50 p-3 rounded flex items-center gap-2">
                        Add Application <FaArrowAltCircleRight />
                      </button>
                    </Link>
                  </div>
                  <div className="-z-2">
                    <DataTable columns={columns} data={application} selectableRows pagination />
                  </div>
                  <Tree isOpen={treeOpen} isClose={closeTree} />
                </div>
              </MDBox>
            </MDBox>
          </DashboardLayout>
        </div>
      )}
    </>
  );
};

export default jobApplication;
