import React, { useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FaRegEye } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SignIn from "layouts/authentication/sign-in"

const DetailView = () => {
  const [isDetailview, setIsDetailView] = useState("table1");
  const [edit, setEdit] = useState(false);
  const handleOpenDetailView = (table) => {
    setIsDetailView((prevTable) => (prevTable === table ? null : table));
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <Link to="/jobapplication/DetailView">{row.Name}</Link>,
      style: {
        color: "blue",
        textDecoration: "underline",
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.PhoneNumber,
      sortable: true,
    },
    {
      name: "job Role",
      selector: (row) => row.jobRole,
    },
  ];

  const data = [
    {
      id: 1,
      Name: "Karthick",
      email: "karthick@gmail.com",
      PhoneNumber: "9876543210",
      jobRole: "painter",
      status: "In progress",
    },
    {
      id: 2,
      Name: "Karthick",
      email: "karthick@gmail.com",
      PhoneNumber: "9876543210",
      jobRole: "welter",
    },
  ];
  ////edit action///
  const handleEdit = () => {
    setEdit(true);
  };
  const handleSave = () => {
    setEdit(false);
  };

  return (
    <>
    { !sessionId ? <SignIn /> :
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <div className="w-full bg-white-500 p-4 shadow">
            <div className="w-full">
              <div className="flex justify-between py-2">
                <div>Karthick Raj</div>
                <div className="flex flex-row align-middle">
                  {!edit ? (
                    <button
                      onClick={handleEdit}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    >
                      Edit
                    </button>
                  ) : (
                    <>
                    <div className="gap-2">
                    <button
                      onClick={handleSave}
                      className="bg-green-600  text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mx-2"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleSave}
                      className="bg-red-600  text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    >
                      Cancel
                    </button>
                    </div>
                    </>
                  )}

                  <div className="flex items-center justify-center px-3 h-full">
                    <FaArrowRight />
                  </div>
                </div>
              </div>
              <div className="flex justify-start  w-full p-2 cursor-pointer gap-4">
                <div
                  className={`cursor-pointer rounded px-3 shadow ${
                    isDetailview === "table1" ? "text-blue-900 bg-white" : "bg-blue-900 text-white"
                  }`}
                  onClick={() => handleOpenDetailView("table1")}
                >
                  Personal Details
                </div>
                <div
                  className={`cursor-pointer rounded px-3 shadow ${
                    isDetailview === "table2" ? "text-blue-900 bg-white" : "bg-blue-900 text-white"
                  }`}
                  onClick={() => handleOpenDetailView("table2")}
                >
                  Education Details
                </div>
                <div
                  className={`cursor-pointer rounded px-3 shadow ${
                    isDetailview === "table3" ? "text-blue-900 bg-white" : "bg-blue-900 text-white"
                  }`}
                  onClick={() => handleOpenDetailView("table3")}
                >
                  Experience Details
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              {isDetailview === "table1" && (
                <div className="table w-full">
                  <form className="w-full">
                    <div className="table-row-group">
                      <div className="table-row">
                        <div className="table-cell w-1/5  p-2 ">
                          <div>Category </div>

                          <input
                            type="text"
                            value="painter"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>First Name </div>

                          <input
                            type="text"
                            value="karthick"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Last Name </div>

                          <input
                            type="text"
                            value="Raj"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell w-1/5  p-2">
                          <div>Email </div>

                          <input
                            type="email"
                            value="karthick@gmail.com"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Date of Birth </div>

                          <input
                            type="date"
                            value="01/01/2024"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Phone Number </div>

                          <input
                            type="number"
                            value="9876543210"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row ">
                        <div className="table-cell w-1/5  p-2">
                          <div>Alternative Phone Number </div>

                          <input
                            type="number"
                            value="9876543210"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Gender </div>
                          <div class="dropdown">
                            <select
                              name="gender"
                              id="gender"
                              value="male"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="male" selected>
                                Male
                              </option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Nationality </div>

                          <input
                            type="text"
                            value="Hindu"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row ">
                        <div className="table-cell w-1/5  p-2">
                          <div>Disablity </div>

                          <input
                            type="text"
                            value="No"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Street </div>

                          <input
                            type="text"
                            value="A to Z street"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>City </div>

                          <input
                            type="text"
                            value="Usilampatti"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row ">
                        <div className="table-cell w-1/5  p-2">
                          <div>Country </div>

                          <input
                            type="text"
                            value="India"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>ZipCode </div>

                          <input
                            type="number"
                            value="600028"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Aadhar Card </div>

                          <input
                            type="file"
                            // value="aadhar.pdf"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row ">
                        <div className="table-cell w-1/5  p-2">
                          <div>PassPort </div>

                          <input
                            type="file"
                            // value="passport.pdf"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Pan Card </div>

                          <input
                            type="file"
                            // value="pancard.pdf"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Language known </div>

                          <input
                            type="text"
                            value="Tamil"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {isDetailview === "table2" && (
                <div className="table w-full">
                  <form className="w-full">
                    <div className="table-row-group">
                      <div className="table-row">
                        <div className="table-cell w-1/5  p-2 ">
                          <div>Qualification </div>

                          <input
                            type="text"
                            value="SSLC"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>SSLC </div>
                          <input
                            type="file"
                            // value="SSLC"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>HSC </div>

                          <input
                            type="file"
                            //  value="Raj"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell w-1/5  p-2">
                          <div>Diploma </div>

                          <input
                            type="email"
                            value="karthick@gmail.com"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Bachelor Degree </div>

                          <input
                            type="file"
                            //  value="01/01/2024"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Master Degree </div>

                          <input
                            type="file"
                            //  value="9876543210"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row ">
                        <div className="table-cell w-1/5  p-2">
                          <div>Other </div>

                          <input
                            type="file"
                            //  value="9876543210"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>

                        <div className="table-cell w-1/5  p-2">
                          <div>University </div>

                          <input
                            type="text"
                            value="Anna University"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Graduation Year </div>

                          <input
                            type="number"
                            value="2022"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {isDetailview === "table3" && (
                <div className="table w-full">
                  <form className="w-full">
                    <div className="table-row-group">
                      <div className="table-row">
                        <div className="table-cell w-1/5  p-2 ">
                          <div>Do you have Experience </div>

                          <input
                            type="text"
                            value="yes"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Years of Experience </div>
                          <input
                            type="text"
                            value="2"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Current/Past Position </div>

                          <input
                            type="text"
                            value="Painter"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell w-1/5  p-2">
                          <div>Current/Past Salary </div>

                          <input
                            type="text"
                            value="50000"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Country </div>

                          <input
                            type="text"
                            value="Singapore"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="table-cell w-1/5  p-2">
                          <div>Work Permit file </div>

                          <input
                            type="file"
                            //  value="9876543210"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <div className="table-row ">
                        <div className="table-cell w-1/5  p-2">
                          <div>Cover Letter </div>

                          <input
                            type="text"
                            value="sample text"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>

                        <div className="table-cell w-1/5  p-2">
                          <div>Skills </div>

                          <input
                            type="text"
                            value="painter,welter"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="w-full bg-white-500 p-4 shadow">
            <div className="w-full">
              <div
                className="flex justify-between bg-blue-600 w-full p-2 cursor-pointer"
                onClick={() => handleOpenDetailView("table4")}
              >
                <div className="text-white">Education Details</div>
                <div className="cursor-pointer text-white">
                  {isDetailview === "table4" ? "--" : "+"}
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              {isDetailview === "table4" && (
                <div>
                  {" "}
                  <DataTable columns={columns} data={data} selectableRows pagination />
                </div>
              )}
            </div>
          </div>
          <div className="w-full bg-white-500 p-4 shadow">
            <div className="w-full">
              <div
                className="flex justify-between bg-blue-600 w-full p-2 cursor-pointer"
                onClick={() => handleOpenDetailView("table5")}
              >
                <div className="text-white">Experience details</div>
                <div className="cursor-pointer text-white">
                  {isDetailview === "table5" ? "--" : "+"}
                </div>
              </div>
            </div>
            <div className="w-full mt-4"></div>
            {isDetailview === "table5" && (
              <div>
                {" "}
                <DataTable columns={columns} data={data} selectableRows pagination />
              </div>
            )}
          </div>
        </MDBox>
      </DashboardLayout>
}
    </>
  );
};

export default DetailView;
