import React, { useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import Login from "layouts/authentication/sign-in"

const category = () => {
  const [inputValue, setInputValue] = useState("");
  const [category, setCategory] = useState([]);
  const [checked,setChecked] = useState([])

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAdd = () => {
    if (inputValue.trim() && !category.includes(inputValue)) {
      setCategory((prevCategories) => {
        const newCategories = [...prevCategories, inputValue];
        console.log(newCategories); // Log updated categories
        return newCategories;
      });
      setInputValue(""); 
    }else {
      alert("Category already exists or input is empty.");
    }
  };
  const removeCategory = (index) =>{
    setCategory((previousCategory) => previousCategory.filter((_,i) =>i !==index) )
  }
  const handleChecked = (index) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, checked: !category.checked } : category
      )
    );
  };
  const sessionId = useSelector((state) => state.session.sessionId);

  console.log("Session ID:", sessionId);
  // alert(sessionId);
  const session = localStorage.getItem("session")
  console.log(session);
  console.log("test")

  return (
    <>
    { !sessionId ? <Login /> : 
      <DashboardLayout>
        <DashboardNavbar />

        <div>
          <div className="flex flex-wrap -mx-3 mt-[2rem] mb-6 justify-center w-1/2 max-md:w-full align-top max-md:flex-row ">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                value={inputValue}
                placeholder="Enter the Category"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 py-3 px-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
          <div>
            {category.map((category,index)=>(
               <div className="flex items-center gap-2" key={index}>
               <input type="checkbox"  />
               <p>{category}</p>
               <FaTrash  onClick={()=>removeCategory(index)} className="pointer"/>
             </div>
            ))

           
           
}
          </div>
        </div>
      </DashboardLayout>
}
    </>
  );
};

export default category;
