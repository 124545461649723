/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSessionId } from "../../../Redux/action/sessionAction";
import { setLoginUserId } from "../../../Redux/action/sessionAction";

import axios from "axios";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logoImage from "assets/images/logos/logo.png";
import { Button } from "@mui/material";
import MD5 from "crypto-js/md5";
import { ColorRing } from "react-loader-spinner";

function Basic() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    userName: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const hash = MD5(login.password).toString();
    setLoading(true);
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";

    const method = "login";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = encodeURIComponent(
      JSON.stringify({
        user_auth: {
          user_name: login.userName,
          password: hash,
        },
        name_value_list: [],
      })
    );
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;
    try {
      const response = await axios.get(fullUrl);

      if (response.status === 200 && response.data.id) {
        console.log("Login successful:", response.data);
        const session = dispatch(setSessionId(response.data.id));
        const userId = dispatch(setLoginUserId(response.data.name_value_list.user_id));
        console.log(session);
        console.log(userId);

        navigate("/");

        // Handle successful login, e.g., store token, redirect, etc.
      } else {
        console.error("Login failed:", response.data);
        alert("Incorrect username or password. Please try again.")
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during login:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox>
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <img src={logoImage} alt="Moona" style={{ width: "85px", height: "75px" }} />
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <form onSubmit={handleLogin}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              {" "}
              {/* Changed from component="form" to just role="form" */}
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Username"
                  onChange={handleChange}
                  value={login.userName}
                  name="userName"
                  id="userName"
                  
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  onChange={handleChange}
                  value={login.password}
                  name="password"
                  id="password"
                  fullWidth
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                {loading ? (
                  <div className="flex justify-center">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    variant="gradient"
                    sx={{
                      backgroundColor: "#012034",
                      color: "#ffffff",
                      width: "100%",
                      padding: "6px 8px", // Increase padding for a larger button
                      fontSize: "1rem",
                      "&:hover": {
                        backgroundColor: "#012034", // Slightly lighter shade for hover effect
                      },
                      "&:active": {
                        backgroundColor: "#012034", // Maintain the same color on click
                      },
                    }}
                  >
                    sign in
                  </Button>
                )}
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" sx={{ color: "#012034" }}>
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    sx={{ color: "#012034" }}
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
