import React from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import SignIn from "layouts/authentication/sign-in"

const addReferal = () => {
    const [referral, setReferral] = useState({
      refferid_c:"",
      firstname_c : "",
      lastname_c : "",
      email_c :"",
      phone_number_c : "",
        
    })
    const sessionId = useSelector((state) => state.session.sessionId || null);
    console.log(sessionId);
    const navigate = useNavigate()
    

    const handleReferChange = (e) => {
        const { name, value } = e.target;
        setReferral((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const handleSubmit = async(e) =>{
        e.preventDefault();

        const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";
    
        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "Ref_Refferals",
          name_value_list: [
            { name: "refferid_c", value: referral.refferid_c },
        { name: "firstname_c", value: referral.firstname_c },
        { name: "lastname_c", value: referral.lastname_c },
        { name: "email_c", value: referral.email_c },
        { name: "phone_number_c", value: referral.phone_number_c },
          ]
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
          rest_data
        )}`;
    
        try {
          const response = await axios.post(fullUrl);
          console.log("API Response:", response.data);
          navigate("/referrals")
        } catch  (error) {
          if (error.response) {
            console.error("An error occurred during login:", error.response.data);
            console.error("Status code:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        }
    
    }
  return (
    <>
     {!sessionId ?  <SignIn /> :
    <DashboardLayout>
       <DashboardNavbar />
       <MDBox py={3}>
        <div className='flex flex-col'>
       <form className="w-full flex flex-col justify-center" onSubmit={handleSubmit} >
        <div className="flex justify-end">
       <div className="flex content-end p-5 gap-4">
            <button
              className="cursor-pointer rounded px-3 shadow bg-blue-900 text-white"
              type="submit"
              
            >
              save
            </button>
            <button className="cursor-pointer rounded px-3 shadow bg-red-500 text-white">
              Cancel
            </button>
          </div>
          </div>
          <div className="w-full mt-4">
            
              <div className="table w-full">
                
                  <div className="table-row-group">
                    <div className="flex flex-col content-center">
                     
                      <div className="table-cell    p-2">
                        <div>Refer ID </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={referral.refferid_c}
                          onChange={handleReferChange}
                          name="refferid_c"
                        />
                      </div>
                      <div className="table-cell    p-2">
                        <div>First Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={referral.firstname_c}
                          onChange={handleReferChange}
                          name="firstname_c"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Last Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={referral.lastname_c}
                          onChange={handleReferChange}
                          name="lastname_c"
                        />
                      </div>
                    
                      <div className="table-cell   p-2">
                        <div>Email </div>

                        <input
                          type="email"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={referral.email_c}
                          onChange={handleReferChange}
                          name="email_c"
                        />
                      </div>
                     
                      <div className="table-cell   p-2">
                        <div>Phone Number </div>

                        <input
                          type="number"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={referral.phone_number_c}
                          onChange={handleReferChange}
                          name="phone_number_c"
                        />
                      </div>
                    </div>
                   
                  </div>
               
              </div>
              </div>
              </form>
           
              </div>
       
         <MDBox>
           
         </MDBox>
       </MDBox>
       
     </DashboardLayout>
}
     </>
  )
}

export default addReferal