import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Filter from "../../components/Filter/filter";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowAltCircleRight, FaSearch } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";
import { MdDelete, MdEdit } from "react-icons/md";
import userSessionHandle from "../../userSessionHandle"
import { Audio } from "react-loader-spinner";
import SignIn from "layouts/authentication/sign-in"



const jobListing = () => {
  const [jobListing, setJobListing] = useState([]);
  // const [deleted, setDeleted] = useState(false)

  // userSessionHandle()
  const sessionId = useSelector((state) => state.session.sessionId);
  // const user_id = useSelector((state) => state.session.userId.value);
  // console.log(user_id);

  const navigate = useNavigate()
  
  
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "job_JOB_",
        // query: "ref_refferals_cstm.user_id_c= 1",
        
        select_fields: [
          "id",
          "name",
          "company_name_c",
          "location_c",
          "job_type_c",
          "category_c",
          "required_skills_c",
          "closedate_c"
          
        ],
        // query: id ? `users.id = '${id}'` : "",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
        rest_data
      )}`;

      try {
        const response = await axios.get(fullUrl);
        console.log("API Response:", response.data);
        const entryList = response.data.entry_list;

        // Extracting name_value_list from each entry in entry_list and storing them in the products array
        const products = entryList.map((entry) => entry.name_value_list);
        
        console.log(products);
        setJobListing(products);
        // console.log(products[1].id);

        // console.log(referrals, "data");
      } catch (error) {
        if (error.response) {
          console.error("An error occurred during get data:", error.response.data);
          console.error("Status code:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [jobListing]);

  const deleteJob = async (id) => {
    // setLoading(true);
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "job_JOB_",
      name_value_list: [
        {
          name: "id",
          value: id,
        },
        {
          name: "deleted",
          value: 1,
        },
      ],
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;
    try {
      const response = await axios.post(fullUrl);

      console.log("deleted", response);
      // setDeleted(true);
      deleteJob((prevjobListing) => prevjobListing.filter((jobListing) => jobListing.id.value !== id));
      // setReferral((prevReferrals) => prevReferrals.filter((referral) => referral.id !== id));
    } catch {
      console.error("Error deleting product:");
    }
  };
  const updateJobListing = (id) =>{

    navigate(`/updateJobListing/${id}`)
    alert(`/updateJobListing/${id}`)
      }
  // useEffect(()=>{
  //   deleteRefferal
  // },[referrals])
  const columns = [
    {
      name: "Role",
      selector: (row) => row.name?.value,

      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name_c?.value,

      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location_c?.value,

      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category_c?.value,
    },
    {
      name: "Skills",
      selector: (row) => row.required_skills_c?.value,
      sortable: true,
    },
    {
      name: "Close Date",
      selector: (row) => row.closedate_c?.value,
      sortable: true,
    },

    {
      name: "Actions",
      width: "10%",
      cell: (row) => (
        <div className="py-4 flex flex-row gap-3">
          <MdEdit
            className="text-green-700 text-xl cursor-pointer"
            onClick={() => updateJobListing(row.id.value)}
          />
          <MdDelete
            className="text-red-700 text-xl cursor-pointer"
            onClick={() => deleteJob(row.id.value)}
          />
        </div>
      ),
    },
  ];
  // console.log(referrals.id.value);

  return (
    <>
    {!sessionId ?  <SignIn />  : 
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Filter />
          <MDBox>
            <div class="static overflow-x-auto pt-2 pb-2   ">
              <div className="flex justify-between bg-blue-300 p-4 rounded items-center ">
                <div className="text-blue-950">Job Listing </div>
                <Link to="/addjobListing">
                  <button className="bg-blue-50 p-3 rounded flex items-center gap-2">
                    Add Job <FaArrowAltCircleRight />
                  </button>
                </Link>
              </div>
              <div className="-z-2">
                <DataTable
                  columns={columns}
                  data={jobListing}
                  
                  selectableRows
                  pagination
                />
              </div>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
}
    </>
  );
};

export default jobListing;
