import React from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const addUser = () => {
    const [user, AddUser] = useState({
      user_name:"",
      first_name : "",
      last_name : "",
      email_c :"",
      phone_mobile : "",
      password :"",
      confirmPassword :""
        
    })
    const sessionId = useSelector((state) => state.session.sessionId);
    console.log(sessionId);
    const navigate = useNavigate()
    

    const handleReferChange = (e) => {
        const { name, value } = e.target;
        AddUser((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const handleSubmit = async(e) =>{
        e.preventDefault();

        const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";
    
        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "Users",
          name_value_list: [
            { name: "user_name", value: user.user_name },
            { name: "user_hash", value: user.password },
        { name: "cnfrm_c", value: user.cnfrm_c },
        { name: "first_name", value: user.first_name },
        { name: "last_name", value: user.last_name },
        { name: "email_c", value: user.email_c },
        { name: "phone_mobile", value: user.phone_mobile },
        
          ]
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
          rest_data
        )}`;
    
        try {
          const response = await axios.post(fullUrl);
          console.log("API Response:", response.data);
          navigate("/user")
        } catch  (error) {
          if (error.response) {
            console.error("An error occurred during login:", error.response.data);
            console.error("Status code:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        }
    
    }
  return (
    <>
    <DashboardLayout>
       <DashboardNavbar />
       <MDBox py={3}>
        <div className='flex flex-col'>
       <form className="w-full flex flex-col justify-center" onSubmit={handleSubmit} >
        <div className="flex justify-end">
       <div className="flex content-end p-5 gap-4">
            <button
              className="cursor-pointer rounded px-3 shadow bg-blue-900 text-white"
              type="submit"
              
            >
              save
            </button>
            <button className="cursor-pointer rounded px-3 shadow bg-red-500 text-white">
              Cancel
            </button>
          </div>
          </div>
          <div className="w-full mt-4">
            
              <div className="table w-full">
                
                  <div className="table-row-group">
                    <div className="flex flex-col content-center">
                     
                      <div className="table-cell p-2">
                        <div>User name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.user_name}
                          onChange={handleReferChange}
                          name="user_name"
                        />
                      </div>
                      <div className="table-cell    p-2">
                        <div>First Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.first_name}
                          onChange={handleReferChange}
                          name="first_name"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Last Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.last_name}
                          onChange={handleReferChange}
                          name="last_name"
                        />
                      </div>
                    
                      <div className="table-cell   p-2">
                        <div>Email </div>

                        <input
                          type="email"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.email_c}
                          onChange={handleReferChange}
                          name="email_c"
                        />
                      </div>
                     
                      <div className="table-cell   p-2">
                        <div>Phone Number </div>

                        <input
                          type="number"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.phone_mobile}
                          onChange={handleReferChange}
                          name="phone_mobile"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Password </div>

                        <input
                          type="password"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.password}
                          onChange={handleReferChange}
                          name="password"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Confirm Password </div>

                        <input
                          type="password"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={user.confirmPassword}
                          onChange={handleReferChange}
                          name="confirmPassword"
                        />
                      </div>
                    </div>
                   
                  </div>
               
              </div>
              </div>
              </form>
           
              </div>
       
         <MDBox>
           
         </MDBox>
       </MDBox>
       
     </DashboardLayout>
     </>
  )
}

export default addUser